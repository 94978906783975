
.preloader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .desc {
    color: #333;
    padding-top: 10px;
  }
}
